<template>
	<div>
		<form class='a-booking-form-default a-booking-form-groupmaker'>
			<div class='a-booking-form-row'>
				<div class="a-booking-form-col">
					<v-text-field
						:placeholder="$t('account.home.groupmaker.group_name')"
						name='name'
						v-model='form.name'
						hide-details
						isLarge
						class='a-booking-form-groupmaker-input'
						:invalid='!form.name && errors.name'
					/>
				</div>
				<div class="a-booking-form-col">
					<a-select
						:options='itemsType'
						v-model='form.type'
						:placeholder="$t('account.home.groupmaker.group_type')"
						:errors='!form.type && errors.type'
					/>
				</div>
				<div class="a-booking-form-col">
					<v-text-field
						:placeholder="$t('account.form.placeholder.country')"
						name='country'
						v-model='form.country'
						hide-details
						isLarge
						class='a-booking-form-groupmaker-input'
						:invalid='!form.country && errors.country'
					/>
				</div>
				<div class="a-booking-form-col">
					<v-text-field
						:placeholder="$t('account.form.placeholder.city')"
						name='destination'
						v-model='form.destination'
						hide-details
						isLarge
						class='a-booking-form-groupmaker-input'
						:invalid='!form.destination && errors.destination'
					/>
				</div>
				<div class="a-booking-form-col a-booking-form-groupmaker-people">
					<select-people
						:adults='form.adults'
						:childrenCount='form.children'
						:infant='form.infant'
						:placeholder='$t("account.booking.content.count_tourist")'
						@update-people='updatePeople($event)'
						isInfant
						:errors='!form.adults && errors.adults'
					/>
				</div>
				<div class="a-booking-form-col">
					<p class='a-booking-form-groupmaker-label'>{{$t('account.home.groupmaker.budget_per_person_per_night')}}:</p>
					<div class="a-booking-form-groupmaker-group">
						<v-text-field
							:placeholder="$t('account.form.placeholder.from')"
							name='budgetFrom'
							type='number'
							v-model='form.budgetFrom'
							hide-details
							isLarge
							class='a-booking-form-groupmaker-input'
							:invalid='!form.budgetFrom && errors.budgetFrom'
						/>
						<span class='a-booking-form-groupmaker-group-separator'>—</span>
						<v-text-field
							:placeholder="$t('account.form.placeholder.to')"
							name='budgetTo'
							type='number'
							v-model='form.budgetTo'
							hide-details
							isLarge
							class='a-booking-form-groupmaker-input'
							:invalid='!form.budgetTo && errors.budgetTo'
						/>
					</div>
					<div class="a-booking-form-groupmaker-radio">
						<div class="row">
							<div class="col-auto">
								<v-radio
									id="uah"
									name='currency'
									label="UAH"
									value='UAH'
									v-model='form.currency'
								/>
							</div>
							<div class="col-auto">
								<v-radio
									id="eur"
									name='currency'
									label="EUR"
									value='EUR'
									v-model='form.currency'
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class='a-booking-form-groupmaker-period-date'>
				<v-checkbox
					name='return-place'
					:label="$t('account.home.groupmaker.apply_period_by_dates')"
					v-model='rangeDate'
				/>
			</div>

			<div class='a-booking-form-row'>
				<div class="a-booking-form-col">
					<a-date-picker
						v-model='dateFrom'
						@change='changeDate($event, "From")'
						:placeholder="rangeDate ? `${$t('account.home.groupmaker.date_of_departure')} ${$t('account.with')} - ${$t('account.by')}` : `${$t('account.home.groupmaker.date_of_departure')}`"
						:range='rangeDate'
						class='mobile-hide'
					/>
					<a-date-picker-mobile
						v-model='dateFrom'
						@change='changeDate($event, "From")'
						:placeholder="rangeDate ? `${$t('account.home.groupmaker.date_of_departure')} ${$t('account.with')} - ${$t('account.by')}` : `${$t('account.home.groupmaker.date_of_departure')}`"
						:range='rangeDate'
						class='mobile-show'
					/>
				</div>
				<div class="a-booking-form-col">
					<a-date-picker
						v-model='dateTo'
						@change='changeDate($event, "To")'
						:placeholder="rangeDate ? `${$t('account.home.groupmaker.date_of_arrival')} ${$t('account.with')} - ${$t('account.by')}` : `${$t('account.home.groupmaker.date_of_arrival')}`"
						:range='rangeDate'
						right
						class='mobile-hide'
					/>
					<a-date-picker-mobile
						v-model='dateTo'
						@change='changeDate($event, "To")'
						:placeholder="rangeDate ? `${$t('account.home.groupmaker.date_of_arrival')} ${$t('account.with')} - ${$t('account.by')}` : `${$t('account.home.groupmaker.date_of_arrival')}`"
						:range='rangeDate'
						class='mobile-show'
					/>
				</div>
				<div class="a-booking-form-col">
					<div class='a-booking-form-row'>
						<div class="a-booking-form-col">
							<v-text-field
								:placeholder="$t('account.home.groupmaker.number_of_nights')"
								name='night'
								type='number'
								v-model='form.night'
								hide-details
								isLarge
								class='a-booking-form-groupmaker-input'
								:invalid='!form.night && errors.night'
							/>
						</div>
						<div class="a-booking-form-col">
							<a-select
								:options='itemsHotelType'
								v-model='form.hotelType'
								:placeholder="$t('account.home.groupmaker.hotel_level')"
								:errors='!form.hotelType && errors.hotelType'
							/>
						</div>
					</div>
				</div>
				<div class="a-booking-form-col">
					<a-select
						:options='itemsBoardType'
						v-model='form.boardType'
						:placeholder="$t('account.home.groupmaker.type_of_food')"
						:errors='!form.boardType && errors.boardType'
					/>
				</div>
				<div class="a-booking-form-col a-booking-form-col-12">
					<div class='a-booking-form-groupmaker-stepper full a-booking-form-groupmaker-stepper-row'>
						<div class='a-booking-form-groupmaker-stepper-item' v-for='(item, i) in type_numbers' :key='i'>
							<a-stepper
								:title="`${$t('account.home.groupmaker.room_type')} ${item.title}`"
								:count='form[item.key]'
								@change='form[item.key] = $event'
							/>
						</div>
					</div>
				</div>
			</div>

			<div class='row a-booking-form-action'>
				<div class="col"></div>
				<div class="col-auto">
					<v-button small color='green white--text' @click='groupmaker' :loading='loading_btn' class='a-booking-form-groupmaker-btn'>{{$t('account.buttons.send_a_request')}}</v-button>
				</div>
			</div>
		</form>

		<dialog-success
			:title="$t('account.messages.success.your_request_has_been_sent_successfully')"
			:text="$t('account.messages.success.our_manager_will_contact_you_in_the_near_future')"
			v-model='success'
			@close-dialog="success = false"
		/>
	</div>
</template>

<script>
	import SelectPeople from "@/views/account/home/components/SelectPeople";
	import DialogSuccess from '@/views/account/home/components/DialogSuccess';
	import { groupmaker } from '@/services/request';
	import { i18n } from '@/plugins/i18n';

	export default {
		data: () => ({
			loading_btn: false,
			success: false,
			errors: {},
			form: {
				name: '',
				type: null,
				country: '',
				destination: '',
				adults: 0,
				children: 0,
				infant: 0,
				budgetFrom: null,
				budgetTo: null,
				currency: "UAH",
				dateFrom: null,
				dateTo: null,
				// startDateFrom: null,
				// endDateFrom: null,
				// startDateTo: null,
				// endDateTo: null,
				night: null,
				hotelType: null,
				boardType: null,
				countTypeRoomSingle: 0,
				countTypeRoomDouble: 0,
				countTypeRoomTwin: 0,
				countTypeRoomTriple: 0,
				countTypeRoomQuad: 0,
				countTypeRoomMultibed: 0
			},
			rangeDate: false,
			dateFrom: null,
			dateTo: null,
			type_numbers: [
				{key: 'countTypeRoomSingle', title: 'Single'},
				{key: 'countTypeRoomDouble', title: 'Double'},
				{key: 'countTypeRoomTwin', title: 'Twin'},
				{key: 'countTypeRoomTriple', title: 'Triple'},
				{key: 'countTypeRoomQuad', title: 'Quad'},
				{key: 'countTypeRoomMultibed', title: 'Multibed'}
			]
		}),
		components: {
			SelectPeople,
			DialogSuccess
		},
		computed: {
			itemsType() {
				return [
					{id: i18n.t("account.home.groupmaker.type.leisure"), name: i18n.t("account.home.groupmaker.type.leisure")},
					{id: i18n.t("account.home.groupmaker.type.teaching"), name: i18n.t("account.home.groupmaker.type.teaching")},
					{id: i18n.t("account.home.groupmaker.type.sport"), name: i18n.t("account.home.groupmaker.type.sport")},
					{id: i18n.t("account.home.groupmaker.type.religion"), name: i18n.t("account.home.groupmaker.type.religion")},
					{id: i18n.t("account.home.groupmaker.type.other"), name: i18n.t("account.home.groupmaker.type.other")}
				]
			},
			itemsHotelType() {
				return [
					{id: "1", name: '1*'},
					{id: "2", name: '2*'},
					{id: "3", name: '3*'},
					{id: "4", name: '4*'},
					{id: "5", name: '5*'}
				]
			},
			itemsBoardType() {
				return [
					{id: "RO", name: i18n.t("account.home.groupmaker.boardType.ro")},
					{id: "BB", name: i18n.t("account.home.groupmaker.boardType.bb")},
					{id: "HB", name: i18n.t("account.home.groupmaker.boardType.hb")},
					{id: "FB", name: i18n.t("account.home.groupmaker.boardType.fb")},
					{id: "AI", name: i18n.t("account.home.groupmaker.boardType.ai")}
				]
			}
		},
		methods: {
			groupmaker() {
				this.errors = {};
				this.loading_btn = true;

				groupmaker(this.form).then(() => {
					this.success = true;
					this.clearForm();
				})
				.catch(err => this.errors = err.response.data?.errors ?? {})
				.finally(() => this.loading_btn = false);
			},
			updatePeople($event) {
				this.form.adults = $event[0].adults;
				this.form.children = $event[0].childrenCount;
				this.form.infant = $event[0].infant;
			},
			changeDate($event, name) {
				if(this.rangeDate) {
					this.form[`startDate${name}`] = this.$moment($event[0]).format('YYYY-MM-DD')
					this.form[`endDate${name}`] = this.$moment($event[1]).format('YYYY-MM-DD')
				} else {
					this.form[`date${name}`] = this.$moment($event).format('YYYY-MM-DD')
				}
				this[`date${name}`] = $event;
			},
			clearForm() {
				this.form = {
					name: '',
					type: null,
					country: '',
					destination: '',
					adults: 0,
					children: 0,
					infant: 0,
					budgetFrom: null,
					budgetTo: null,
					currency: "UAH",
					dateFrom: null,
					dateTo: null,
					night: null,
					hotelType: null,
					boardType: null,
					countTypeRoomSingle: 0,
					countTypeRoomDouble: 0,
					countTypeRoomTwin: 0,
					countTypeRoomTriple: 0,
					countTypeRoomQuad: 0,
					countTypeRoomMultibed: 0
				},
				this.rangeDate = false;
				this.dateFrom = null;
				this.dateTo = null;
				this.errors = {};

				delete this.form[`startDateFrom`];
				delete this.form[`endDateFrom`];
				delete this.form[`startDateTo`];
				delete this.form[`endDateTo`];
			}
		},
		watch: {
			rangeDate() {
				if(this.rangeDate) {
					delete this.form[`dateFrom`];
					delete this.form[`dateTo`];
				} else {
					delete this.form[`startDateFrom`];
					delete this.form[`endDateFrom`];
					delete this.form[`startDateTo`];
					delete this.form[`endDateTo`];
				}
				this.dateFrom = null;
				this.dateTo = null;
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-booking-form-groupmaker {
		&-input {
			width: 100%;
			margin-bottom: 0;
			input[type=number] {
				-moz-appearance: textfield;
				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
				}
			}
		}
		&-label {
			font-size: 14px;
			font-weight: 300;
			margin-bottom: 5px;
		}
		&-people {
			margin-top: 21px;
		}
		&-stepper {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -12px;
			&.half {
				.a-booking-form-groupmaker-stepper-item {
					flex: 0 0 100%;
					max-width: 100%;
					@include minw( $grid-breakpoints-xs ) {
						flex: 0 0 calc(100% / 3);
						max-width: calc(100% / 3);
					}
				}
			}
			&.full {
				.a-booking-form-groupmaker-stepper-item {
					flex: 0 0 calc(100% / 2);
					max-width: calc(100% / 2);
					@include minw( $grid-breakpoints-xs ) {
						flex: 0 0 calc(100% / 6);
						max-width: calc(100% / 6);
					}
				}
			}
			&-row {
				@include minw( $grid-breakpoints-xs ) {
					margin-bottom: 40px;
				}
			}
			&-item {
				padding: 0 12px;
				margin-top: 10px;
				@include minw( $grid-breakpoints-xs ) {
					min-width: 162px;
				}
				@include maxw( $grid-breakpoints-xs - 1 ) {
					margin-bottom: 10px;
				}
			}
		}
		&-group {
			display: flex;
			align-items: center;
			&-separator {
				font-size: 16px;
				font-weight: 700;
				padding: 0 16px;
			}
		}
		&-radio {
			padding-top: 25px;
			display: flex;
			align-items: center;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				.row {
					justify-content: center;
				}
			}
		}
		&-period-date {
			padding-top: 25px;
			padding-bottom: 10px;
		}
		&-btn {
			@include minw( $grid-breakpoints-xs ) {
				min-width: 250px;
			}
		}
	}
</style>
